<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <FormItem>
        <span>角色名称：</span>
        <!--        <i-input v-model="roleName" placeholder="请输入" class="iviewIptWidth250"></i-input>-->
        <Select class="iviewIptWidth250" v-model="roleName" clearable filterable>
          <Option v-for="item in roleList" :value="item.role_name" :key="item.id + item.role_name">{{ item.role_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="po-create-number" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="addPersons" v-if="insertAble">新增</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :loading="isLoad" :isLoad="isLoad" totalText="条记录"></Table>
    <Modal v-model="addState" :title="title" class-name="vertical-center-modal" width="626" :mask-closable="false">
      <div v-if="addState">
        <div class="clearfix">
          <div class="item"><span>角色名称：</span><i-input v-model="from.role_name" placeholder="请输入"></i-input></div>
          <div class="item"><span>角色描述：</span><i-input v-model="from.role_desc" placeholder="请输入"></i-input></div>
          <!--          <div class="item"><span>所属仓库：</span><i-input v-model="from.role_store" placeholder="请输入"></i-input></div>-->
          <!--          <div class="item"><span>保留字段1：</span><i-input v-model="from.save_word1" placeholder="请输入"></i-input></div>-->
          <!--          <div class="item"><span>保留字段2：</span><i-input v-model="from.save_word2" placeholder="请输入"></i-input></div>-->
        </div>
      </div>
      <div slot="footer">
        <span v-if="title === '新增'" class="pageBtn finger btnSure" @click="ok">确定</span>
        <span v-else class="pageBtn finger btnSure" @click="changeRole">修改</span>
      </div>
    </Modal>
    <tips-component showModal="delete" v-if="tipStatus" @cancleBtn="tipStatus = false" @sureBrn="sureBrn"></tips-component>
  </div>
</template>
<script>
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
export default {
  components: {
    Table,
    TipsComponent,
  },
  data() {
    return {
      tipStatus: false,
      isLoad: true,
      roleName: '',
      total: 22,
      pages: {
        page: 1,
        rows: 10,
      },
      addState: false,
      listData: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 68,
        },
        {
          title: '角色ID',
          key: 'id',
          align: 'center',
          width: 250,
        },
        {
          title: '角色名称',
          key: 'role_name',
          align: 'center',
          width: 250,
        },
        {
          title: '角色类型',
          key: 'type_str',
          align: 'center',
          width: 250,
        },
        {
          title: '角色描述',
          key: 'role_desc',
          align: 'center',
          minWidth: 280,
        },
        // {
        //   title: '操作',
        //   align: 'center',
        //   width: 200,
        //   render: (h, param) => {
        //     return h('div', [
        //       h(
        //         'span',
        //         {
        //           style: {
        //             marginRight: '50px',
        //             color: '#389AFC',
        //             fontSize: '14px',
        //             cursor: 'pointer',
        //           },
        //           on: {
        //             click: () => {
        //               this.change(param.row)
        //             },
        //           },
        //         },
        //         '修改'
        //       ),
        //       h(
        //         'span',
        //         {
        //           style: {
        //             color: '#389AFC',
        //             fontSize: '14px',
        //             cursor: 'pointer',
        //           },
        //           on: {
        //             click: () => {
        //               this.deleteRow(param.row)
        //             },
        //           },
        //         },
        //         '删除'
        //       ),
        //     ])
        //   },
        // },
      ],
      from: {
        role_id: '',
        role_name: '',
        role_desc: '',
        // role_store: '',
        // save_word1: '',
        // save_word2: '',
      },
      title: '新增',
      deleteState: false,
      deleteId: '',
      roleList: [],

      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  created() {
    this.queryList()
    this.queryRoleList()
    this.getRightVisibal()
  },
  methods: {
    sureBrn() {
      this.$http.Delete(this.$api.permissionRole, this.deleteId, true).then(res => {
        this.queryList()
        this.queryRoleList()
        this.tipStatus = false
      })
    },
    // 点击查询
    query() {
      this.pages.page = 1
      this.queryList()
    },
    queryRoleList() {
      let data = {
        role_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionRoleList, data, true).then(res => {
        this.roleList = res.data
      })
    },
    // 查询列表
    queryList() {
      this.isLoad = true
      let data = {
        role_name: this.roleName,
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.$http.get(this.$api.permissionRoleList, data, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
      })
    },
    // 确认添加
    ok() {
      if (!this.from.role_name.trim()) {
        this.$Message.warning('请填写角色名称')
        return
      }
      if (!this.from.role_desc.trim()) {
        this.$Message.warning('请填写角色描述')
        return
      }
      this.$http.post(this.$api.permissionRoleList, this.from, true).then(res => {
        this.addState = false
        this.queryList()
        this.queryRoleList()
        this.$Message.success('新增角色成功')
      })
    },
    changeRole() {
      if (!this.from.role_name.trim()) {
        this.$Message.warning('请填写角色名称')
        return
      }
      if (!this.from.role_desc.trim()) {
        this.$Message.warning('请填写角色描述')
        return
      }
      let data = {
        role_name: this.from.role_name,
        role_desc: this.from.role_desc,
      }
      this.$http.Put(this.$api.permissionRole, this.from.id, data, true).then(res => {
        this.addState = false
        this.queryList()
        this.queryRoleList()
      })
    },
    addPersons() {
      this.title = '新增'
      this.from = {
        role_name: '',
        role_desc: '',
      }
      this.addState = true
    },
    changePage(e) {
      this.pages.page = e
      this.queryList()
    },
    // 修改
    change(row) {
      this.title = '修改'
      this.from = JSON.parse(JSON.stringify(row))
      this.addState = true
    },
    // 删除
    deleteRow(row) {
      this.deleteId = row.id
      this.tipStatus = true
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = rightStr.modify
      this.searchAble = rightStr.search
    },
  },
}
</script>
<style scoped lang="less">
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  span {
    width: 130px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  span::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
</style>
